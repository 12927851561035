<template>
  <div>
    <el-drawer
      title="关系维护"
      :visible="youtubeUserListShow"
      size="1000px"
      @close="close"
      @opened="openInit"
    >
      <div>
        <div>
          <p>当前账号</p>
          <div class="pl-3">
            <p>账号：{{ youtubeChannel.googleEmail }}</p>
            <p>频道：{{ youtubeChannel.channelName }}</p>
          </div>
        </div>
        <ad-table-page
          :showForm="false"
          v-model="formData"
          :btnList="btnList"
          :tableConfig="tableConfig"
          class="h-full"
          :fieldList="[]"
          :formPrams="{
            inline: true,
            size: 'medium',
          }"
        >
          <template #avatar="{ row }">
            <div>
              <el-image
                :src="row.avatar"
                fit="cover"
                class="rounded-full overflow-hidden w-[64px] h-[64px]"
                v-if="row.avatar"
              ></el-image>
              <span v-else>--</span>
            </div>
          </template>
        </ad-table-page>
      </div>
    </el-drawer>
    <AddChannelUser
      :addUserShow.sync="addUserShow"
      :youtubeChannel="youtubeChannel"
      @getList="reloadTable"
    />
  </div>
</template>
<script>
import { adTablePage } from '@adCommonComponent';
import { channelUserList, deleteChannelUser } from '@/api/google/youtube.js';
import AddChannelUser from '@/views/google/components/AddChannelUser/index.vue';
import { userTypes } from '@/views/google/youtube/index.js';
export default {
  props: {
    youtubeUserListShow: {
      type: Boolean,
      default: false,
    },
    youtubeChannel: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    adTablePage,
    AddChannelUser,
  },
  data() {
    return {
      formData: {},
      addUserShow: false,
      btnList: [
        {
          btnType: 'text',
          text: '添加授权用户',
          click: this.addUser,
          size: 'large',
        },
      ],
      tableConfig: {
        api: channelUserList,
        rowKey: 'recordId',
        columns: [
          {
            label: '头像',
            prop: 'avatar',
          },

          {
            label: '用户id',
            prop: 'userId',
          },
          {
            label: '用户名称',
            prop: 'userName',
          },
          {
            label: '权限',
            prop: 'userType',
            formatter: (row) => {
              return userTypes.find((item) => item.id == row.userType).name;
            },
          },
        ],
        operation: {
          width: 100,
          columns: [
            {
              type: 'text',
              text: '删除',
              visible: (row) => row.userType != 'OWNER',
              click: (row) => this.delUser(row),
            },
          ],
        },
        refresh: true,
        formatterParams: this.formatterParams,
        formatterTableData(res) {
          return {
            list: res.data,
          };
        },
      },
    };
  },
  methods: {
    /**
     * 初始化请求参数
     */
    formatterParams(params) {
      let { order, pageNum, prop, ...data } = params;
      return {
        page: pageNum,
        ...data,
        youtubeChannelId: this.youtubeChannel.id,
      };
    },
    /**
     * 打开弹窗初始化
     */
    openInit() {
      //   this.videoList = [];
      this.reloadTable();
    },
    /**
     * 刷新列表
     */
    reloadTable() {
      this.tableConfig.refresh = !this.tableConfig.refresh;
    },
    /**
     * 添加授权用户
     */
    addUser() {
      console.log('111');
      this.addUserShow = true;
    },
    /** 移除授权用户 */
    delUser(row) {
      console.log(row);
      let params = {
        id: row.id,
        youtubeChannelId: row.youtubeChannelId,
      };
      this.$confirm('确定要移除当前用户的授权吗', '提示', {
        dangerouslyUseHTMLString: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          console.log('aaaa');
          this.$showLoading();
          deleteChannelUser(params).then((res) => {
            this.$hideLoading();
            if (res.code == 0) {
              this.reloadTable();
            }
          });
        })
        .catch(() => {
          console.log('bbb');
        });
    },
    /**
     * 关闭弹窗
     */
    close() {
      this.$emit('update:youtubeUserListShow', false);
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-drawer__body {
  position: relative;
  padding: 20px;
}
::v-deep .videoItem {
  height: 240px;
  .el-checkbox__input {
    position: absolute;
  }
  .el-checkbox__label {
    height: 240px;
  }
}
</style>
