<template>
  <div class="log p-2 bg-[#fff] h-full">
    <ad-table-page
      :showForm="true"
      v-model="formData"
      :tableConfig="tableConfig"
      :btnList="btnList"
      :filedList="filedList"
      class="h-full"
      :formPrams="{
        inline: true,
        size: 'medium',
      }"
      @selectChange="selectChange"
      @reset="formData = {}"
    >
      <template #enable="{ row }">
        <div>
          <span v-if="row.enable">
            <i class="point rounded-full inline-block w-1 h-1 mr-2 align-middle bg-green-500"></i>启用
          </span>
          <span v-else> <i class="point rounded-full inline-block w-1 h-1 mr-2 align-middle bg-red-500"></i>禁用 </span>
        </div>
      </template>
      <template #displayMark="{ row }">
        <EditChannelMark
          :row="row"
          @setChannelMark="(v) => setChannelMark(row.id, v)"
        />
        <!-- <div>
          <span>{{ row.displayMark }}</span>
          <el-button
            type="text"
            icon="el-icon-edit"
          ></el-button>
        </div> -->
      </template>
    </ad-table-page>
    <!-- 上传记录 -->
    <UploadVideoRecord :uploadVideoRecordShow.sync="uploadVideoRecordShow" />
    <!-- 关系维护 -->
    <YoutubeUserList
      :youtubeUserListShow.sync="youtubeUserListShow"
      :youtubeChannel="currentRow"
    />
    <!-- 上传视频 -->
    <UploadVideoYoutube
      :uploadVideoYoutubeShow.sync="uploadVideoYoutubeShow"
      :youtubeChannel="currentRow"
      :accountDisable="true"
      @updateValue="updateValue"
    />
  </div>
</template>
<script>
import { adTablePage } from '@adCommonComponent';
import {
  getChannelPageList,
  setChannelStatus,
  deleteChannel,
  syncChannels,
  getAuthUrl,
  uploadVideo,
  asyncUploadVideo,
} from '@/api/google/youtube';
import UploadVideoRecord from '@/views/google/components/UploadVideoRecord/index.vue';
import YoutubeUserList from '@/views/google/components/YoutubeUserList/index.vue';
import UploadVideoYoutube from '@/views/google/components/UploadVideoYoutube/index.vue';
import EditChannelMark from '@/views/google/components/EditChannelMark/index.vue';
export default {
  components: { adTablePage, UploadVideoRecord, YoutubeUserList, UploadVideoYoutube, EditChannelMark },
  data() {
    return {
      formData: {},
      selectRow: [],
      params: {},
      uploadVideoRecordShow: false, //上传记录弹窗
      youtubeUserListShow: false, //关系维护弹窗
      uploadVideoYoutubeShow: false, //上传视频弹窗
      currentRow: {},
      //   表单配置
      filedList: {
        googleEmail: {
          label: '',
          key: 'googleEmail',
          type: 'input',
          placeholder: 'YouTube账号',
        },
        channelName: {
          label: '',
          key: 'channelName',
          type: 'input',
          placeholder: 'YouTube频道',
        },
        creatorName: {
          label: '',
          key: 'creatorName',
          type: 'input',
          placeholder: '授权人姓名',
        },
        enable: {
          label: '',
          key: 'enable',
          type: 'select',
          placeholder: '标记状态',
          options: [
            { name: '启用', id: true },
            { name: '禁用', id: false },
          ],
        },
        date: {
          label: '',
          key: 'date',
          type: 'dateRange',
          startKey: 'startDate',
          endKey: 'endDate',
          config: {
            'value-format': 'yyyy-MM-dd HH:mm:ss',
            'picker-options': {
              disabledDate(time) {
                return time.getTime() > Date.now();
              },
            },
          },
        },
        btn: {
          type: 'btn',
        },
      },
      // 表格配置
      tableConfig: {
        api: getChannelPageList,
        rowKey: 'id',
        columns: [
          {
            label: 'YouTube账号',
            prop: 'displayName',
            width: '200px',
          },

          {
            label: 'YouTube频道',
            prop: 'channelName',
          },
          {
            label: '标记状态',
            prop: 'enable',
          },
          {
            label: '最近授权人',
            prop: 'lastAuthorizeUser',
          },
          {
            label: '授权时间',
            prop: 'lastAuthorizeTime',
          },
          {
            label: '备注',
            prop: 'displayMark',
          },
        ],
        operation: {
          width: 240,
          columns: [
            {
              type: 'text',
              text: '上传视频',
              click: (row) => this.uploadVideo(row),
            },
            {
              type: 'text',
              text: '关系维护',
              click: (row) => this.userManagement(row),
            },
            {
              type: 'text',
              text: '删除',
              click: (row) => this.delChannel(row),
            },
          ],
        },
        showPage: true,
        refresh: true,
        showSelectBox: true,
        selectable: true,
        formatterParams(params) {
          console.log(params);
          let { order, pageNum, prop, ...data } = params;
          //   data.dateHour = encodeURIComponent(data.dateHour);
          return {
            page: pageNum,
            ...data,
          };
        },
        formatterTableData(res) {
          return {
            list: res.data.list,
            total: res.data.total,
          };
        },
      },
    };
  },
  computed: {
    btnList() {
      return [
        {
          type: 'dropdown',
          text: '调整状态',
          disabled: !this.selectRow.length,
          dropDownList: [
            { text: '启用', click: this.setEnableTrue },
            { text: '禁用', click: this.setEnableFalse },
          ],
          size: 'medium',
        },
        {
          btnType: 'primary',
          text: '上传记录',
          click: this.uploadLog,
          size: 'medium',
        },
        {
          btnType: 'primary',
          text: '授权',
          click: this.addYouTubeAccount,
          size: 'medium',
        },
        {
          btnType: 'primary',
          text: '同步',
          disabled: !this.selectRow.length,
          click: this.syncYouTubeAccount,
          size: 'medium',
        },
      ];
    },
  },
  methods: {
    formatterParams(params) {},
    setEnableFalse() {
      this.setEnable(false);
    },
    setEnableTrue() {
      this.setEnable(true);
    },
    /**
     * 设置状态
     */
    setEnable(enable) {
      this.$showLoading();
      setChannelStatus({ youtubeChannelIds: this.selectRow.map((v) => v.id), enable: enable }).then((res) => {
        this.$hideLoading();
        if (res.code == 0) {
          this.refreshTable();
        }
      });
    },
    /**
     * 上传记录
     */
    uploadLog() {
      this.uploadVideoRecordShow = true;
    },
    /**
     * 删除频道
     */
    delChannel(row) {
      console.log(row);
      this.$confirm('删除后，该YouTube账号将无法上传视频，确认进行该操作吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        deleteChannel({ id: row.id }).then(() => {
          this.refreshTable();
        });
      });
    },
    /**
     * 上传视频
     * @param {*} row
     */
    uploadVideo(row) {
      this.currentRow = row;
      this.uploadVideoYoutubeShow = true;
    },
    /**
     *接收上传视频选择的文件
     * @param {*} videoList
     */
    updateValue(fileList) {
      fileList.forEach((item) => {
        this.uploadFile(item);
      });
    },
    /**
     * 上传视频到YouTube
     */
    uploadFile(data) {
      console.log(data);
      asyncUploadVideo(data)
        .then((res) => {
          this.$message({
            type: 'success',
            message: '上传成功，请稍后去上传记录查看上传结果',
          });
        })
        .catch(() => {});
    },
    // 关系维护
    userManagement(row) {
      this.currentRow = row;
      this.youtubeUserListShow = true;
    },
    // 授权youtube账号
    addYouTubeAccount() {
      //   this.$message('授权');
      getAuthUrl({ oauthClientType: 'YOUTUBE' }).then((res) => {
        if (res.code == 0) {
          window.open(res.data);
        }
      });
    },
    // 同步youtube 账号
    syncYouTubeAccount() {
      syncChannels({ ids: this.selectRow.map((v) => v.youtubeCredentialId) }).then((res) => {
        if (res.code == 0) {
          this.$message({
            type: 'success',
            message: '同步成功',
          });
          this.refreshTable();
        }
      });
    },
    /**
     * 表格选择监听
     */
    selectChange(v) {
      console.log(v);
      //   return;
      this.selectRow = [...v];
    },
    /**
     * 刷新列表
     */
    refreshTable() {
      this.tableConfig.refresh = !this.tableConfig.refresh;
    },
    /**
     * 修改备注
     */
    setChannelMark(key, value) {
      this.refreshTable();
    },
  },
  async created() {
    // await this.$store.dispatch('app/closeSideBar');
  },
  mounted() {
    this.refreshTable();
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-table__body-wrapper {
  height: calc(100vh - 280px);
  overflow-y: auto;
  overflow-x: hidden;
}
</style>
