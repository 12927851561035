export const uploadStatus = [
  { name: '成功', id: 'SUCCESS' },
  { name: '失败', id: 'FAILED' },
  { name: '上传中', id: 'UPLOADING' },
  { name: '未开始', id: 'NOT_START' },
];

export const userTypes = [
  { id: 'OWNER', name: '所有者' },
  { id: 'SHARED', name: '管理员' },
];
